import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from '../pages/SignIn'
import RealTimeStockData from '../RealTimeStockData'
import Dashboard from '../pages/Dashboard'

const DashboardRoute = () => {
    return (
        <>
            <Routes>
                {/* SignIn routes */}
                <Route path="/" element={<SignIn />} />
                <Route path="/login" element={<SignIn />} />

                {/* Dashboard routes */}
                <Route path="/main-dashboard" element={<Dashboard />}>
                    <Route path="stock" element={<RealTimeStockData />} />
                    {/* Redirect any unmatched routes to the stock page */}
                    <Route path="*" element={<Navigate to="/main-dashboard/stock" replace />} />
                </Route>

                {/* Redirect any unmatched routes to login */}
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </>
    )
}

export default DashboardRoute