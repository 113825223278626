import React from 'react'
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';

const Dashboard = () => {
  return (
    <>
      <section className='dashboard_main'>
        <div className="side_navbar_panel">
          <Sidebar />
        </div>
        <div className="right_side_panel">
          <DashboardHeader />
          {/*<SidePanelRoute />*/}
          <Outlet></Outlet>
        </div>
      </section>
    </>)
}

export default Dashboard