import React, { useState } from "react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";


const SignIn = () => {
  const navigate = useNavigate();
  

  const [userData, setUserData] = useState({
    userId: "",
    password: "",
  });

  const [passwordShow, setPasswordShow] = useState(false);

  const submitHandler = async () => {
    try {
     
        navigate("/main-dashboard/stock")
     
    } catch (error) {
      swal({ text: error.message, icon: "warning" });
    }
  };

  return (
    <>
      <section className="sign_main_section">
        <div className="sign_box">
          <div className="row p-0">
            <div className="col-6 p-0">
              <div className="head_img_box">
              </div>
            </div>
            <div className="col-6 m-0 p-0">
              <div className="sign_input_box">
                <h3 className="signin_heading">Sign In</h3>

                <div className="from_div">
                  <div className="row mb-4 sign_input_main">
                    <div className="col-lg-5 col-md-5 label_div">
                      <label>UserId</label>
                    </div>
                    <div className="col-lg-7 col-md-7 input_div">

                      <input className="form-control" value={userData.userId}
                        onChange={(event) => { setUserData({ ...userData, userId: event.target.value.trim(), }); }}
                        type="text" placeholder="User ID"  />
                    </div>
                  </div>

                  <div className="row mb-4 sign_input_main">
                    <div className="col-lg-5 col-md-5 label_div">
                      <label>Password</label>
                    </div>
                    <div className="col-lg-7 col-md-7 input_div">
                      <input className="form-control" value={userData.password} onChange={(event) => {
                        setUserData({ ...userData, password: event.target.value.trim(), });
                      }} type={passwordShow ? "text" : "password"} placeholder="Password"  />
                      <div className="password_icon">
                      <i className= {passwordShow ? "fa-solid fa-eye-slash icon_color" : "fa-solid fa-eye icon_color"} onClick={() => setPasswordShow(!passwordShow)}></i>
                      </div>
                    </div>
                  </div>

                  <div className="input_div">
                    <button onClick={submitHandler}>Login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
