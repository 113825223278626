import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";



const Sidebar = () => {


    return (
        <>
            <section className="side_navbar_main">
                <div className="logo_main">
                    <img src="/logo/stock.png" alt="logo" />
                </div>
                <ul className="side_navbar_tab">
                    <NavLink to="/main-dashboard/stock">
                        <li>
                            <div className="tab_icon">
                                <i className="fa-solid fa fa-line-chart"></i>
                            </div>
                            <div className="tab_title">Stock</div>
                        </li>
                    </NavLink>
                    <NavLink to="/main-dashboard/stock">
                        <li>
                            <div className="tab_icon">
                                <i className="fa-solid fa-user-plus"></i>
                            </div>
                            <div className="tab_title">My Profile</div>
                        </li>
                    </NavLink>
                    <NavLink to="/main-dashboard/stock">
                        <li>
                            <div className="tab_icon">
                                <i className="fa-solid fa fa-home"></i>
                            </div>
                            <div className="tab_title">Home</div>
                        </li>
                    </NavLink>

                    <NavLink to="/main-dashboard/stock">
                        <li>
                            <div className="tab_icon">
                                <i className="fa-solid fa fa-bell"></i>
                            </div>
                            <div className="tab_title">Notifiactions</div>
                        </li>
                    </NavLink>
                    <NavLink to="/main-dashboard/stock">
                        <li>
                            <div className="tab_icon">
                                <i className="fa-solid fa fa-commenting"></i>
                            </div>
                            <div className="tab_title">Messages</div>
                        </li>
                    </NavLink>



                </ul>
            </section >
        </>
    );
};

export default Sidebar;
