export const instruments = [
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1922,
        "InstrumentType": 8,
        "Name": "KOTAKBANK",
        "DisplayName": "KOTAKBANK",
        "ISIN": "INE237A01028",
        "Description": "KOTAKBANK-EQ",
        "Series": "EQ",
        "NameWithSeries": "KOTAKBANK-EQ",
        "InstrumentID": 1100100001922,
        "PriceBand": {
            "High": 2063.7,
            "Low": 1688.5,
            "HighString": "2063.70",
            "LowString": "1688.50",
            "CreditRating": "1688.50-2063.70",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 51691,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "KOTAK MAHINDRA BANK LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1333,
        "InstrumentType": 8,
        "Name": "HDFCBANK",
        "DisplayName": "HDFCBANK",
        "ISIN": "INE040A01034",
        "Description": "HDFCBANK-EQ",
        "Series": "EQ",
        "NameWithSeries": "HDFCBANK-EQ",
        "InstrumentID": 1100100001333,
        "PriceBand": {
            "High": 1828.6,
            "Low": 1496.2,
            "HighString": "1828.60",
            "LowString": "1496.20",
            "CreditRating": "1496.20-1828.60",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 53400,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HDFC BANK LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 383,
        "InstrumentType": 8,
        "Name": "BEL",
        "DisplayName": "BEL",
        "ISIN": "INE263A01024",
        "Description": "BEL-EQ",
        "Series": "EQ",
        "NameWithSeries": "BEL-EQ",
        "InstrumentID": 1100100000383,
        "PriceBand": {
            "High": 315.55,
            "Low": 258.25,
            "HighString": "315.55",
            "LowString": "258.25",
            "CreditRating": "258.25-315.55",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 343559,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BHARAT ELECTRONICS LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 10
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 14977,
        "InstrumentType": 8,
        "Name": "POWERGRID",
        "DisplayName": "POWERGRID",
        "ISIN": "INE752E01010",
        "Description": "POWERGRID-EQ",
        "Series": "EQ",
        "NameWithSeries": "POWERGRID-EQ",
        "InstrumentID": 1100100014977,
        "PriceBand": {
            "High": 367.75,
            "Low": 300.95,
            "HighString": "367.75",
            "LowString": "300.95",
            "CreditRating": "300.95-367.75",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 297619,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "POWER GRID CORP. LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 5900,
        "InstrumentType": 8,
        "Name": "AXISBANK",
        "DisplayName": "AXISBANK",
        "ISIN": "INE238A01034",
        "Description": "AXISBANK-EQ",
        "Series": "EQ",
        "NameWithSeries": "AXISBANK-EQ",
        "InstrumentID": 1100100005900,
        "PriceBand": {
            "High": 1302.65,
            "Low": 1065.85,
            "HighString": "1302.65",
            "LowString": "1065.85",
            "CreditRating": "1065.85-1302.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 83512,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "AXIS BANK LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 11630,
        "InstrumentType": 8,
        "Name": "NTPC",
        "DisplayName": "NTPC",
        "ISIN": "INE733E01010",
        "Description": "NTPC-EQ",
        "Series": "EQ",
        "NameWithSeries": "NTPC-EQ",
        "InstrumentID": 1100100011630,
        "PriceBand": {
            "High": 464.95,
            "Low": 380.45,
            "HighString": "464.95",
            "LowString": "380.45",
            "CreditRating": "380.45-464.95",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 232719,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "NTPC LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 10999,
        "InstrumentType": 8,
        "Name": "MARUTI",
        "DisplayName": "MARUTI",
        "ISIN": "INE585B01010",
        "Description": "MARUTI-EQ",
        "Series": "EQ",
        "NameWithSeries": "MARUTI-EQ",
        "InstrumentID": 1100100010999,
        "PriceBand": {
            "High": 14238.5,
            "Low": 11649.7,
            "HighString": "14238.50",
            "LowString": "11649.70",
            "CreditRating": "11649.70-14238.50",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 7545,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "MARUTI SUZUKI INDIA LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 1
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 5258,
        "InstrumentType": 8,
        "Name": "INDUSINDBK",
        "DisplayName": "INDUSINDBK",
        "ISIN": "INE095A01012",
        "Description": "INDUSINDBK-EQ",
        "Series": "EQ",
        "NameWithSeries": "INDUSINDBK-EQ",
        "InstrumentID": 1100100005258,
        "PriceBand": {
            "High": 1492,
            "Low": 1220.8,
            "HighString": "1492.00",
            "LowString": "1220.80",
            "CreditRating": "1220.80-1492.00",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 73225,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "INDUSIND BANK LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 11723,
        "InstrumentType": 8,
        "Name": "JSWSTEEL",
        "DisplayName": "JSWSTEEL",
        "ISIN": "INE019A01038",
        "Description": "JSWSTEEL-EQ",
        "Series": "EQ",
        "NameWithSeries": "JSWSTEEL-EQ",
        "InstrumentID": 1100100011723,
        "PriceBand": {
            "High": 1108.1,
            "Low": 906.7,
            "HighString": "1108.10",
            "LowString": "906.70",
            "CreditRating": "906.70-1108.10",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 97818,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "JSW STEEL LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 13
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23278,
        "InstrumentType": 8,
        "Name": "JSWSTEEL",
        "DisplayName": "JSWSTEEL",
        "ISIN": "INE019A01038",
        "Description": "JSWSTEEL-T0",
        "Series": "T0",
        "NameWithSeries": "JSWSTEEL-T0",
        "InstrumentID": 1100100023278,
        "PriceBand": {
            "High": 1017.45,
            "Low": 997.35,
            "HighString": "1017.45",
            "LowString": "997.35",
            "CreditRating": "997.35-1017.45",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 97818,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "JSW STEEL LIMITED-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 13
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 467,
        "InstrumentType": 8,
        "Name": "HDFCLIFE",
        "DisplayName": "HDFCLIFE",
        "ISIN": "INE795G01014",
        "Description": "HDFCLIFE-EQ",
        "Series": "EQ",
        "NameWithSeries": "HDFCLIFE-EQ",
        "InstrumentID": 1100100000467,
        "PriceBand": {
            "High": 796,
            "Low": 651.3,
            "HighString": "796.00",
            "LowString": "651.30",
            "CreditRating": "651.30-796.00",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 137742,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HDFC LIFE INS CO LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 21808,
        "InstrumentType": 8,
        "Name": "SBILIFE",
        "DisplayName": "SBILIFE",
        "ISIN": "INE123W01016",
        "Description": "SBILIFE-EQ",
        "Series": "EQ",
        "NameWithSeries": "SBILIFE-EQ",
        "InstrumentID": 1100100021808,
        "PriceBand": {
            "High": 1907.95,
            "Low": 1561.05,
            "HighString": "1907.95",
            "LowString": "1561.05",
            "CreditRating": "1561.05-1907.95",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 57109,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "SBI LIFE INSURANCE CO LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 16675,
        "InstrumentType": 8,
        "Name": "BAJAJFINSV",
        "DisplayName": "BAJAJFINSV",
        "ISIN": "INE918I01026",
        "Description": "BAJAJFINSV-EQ",
        "Series": "EQ",
        "NameWithSeries": "BAJAJFINSV-EQ",
        "InstrumentID": 1100100016675,
        "PriceBand": {
            "High": 2063.25,
            "Low": 1688.15,
            "HighString": "2063.25",
            "LowString": "1688.15",
            "CreditRating": "1688.15-2063.25",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 52689,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BAJAJ FINSERV LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3499,
        "InstrumentType": 8,
        "Name": "TATASTEEL",
        "DisplayName": "TATASTEEL",
        "ISIN": "INE081A01020",
        "Description": "TATASTEEL-EQ",
        "Series": "EQ",
        "NameWithSeries": "TATASTEEL-EQ",
        "InstrumentID": 1100100003499,
        "PriceBand": {
            "High": 175.69,
            "Low": 143.74,
            "HighString": "175.69",
            "LowString": "143.74",
            "CreditRating": "143.74-175.69",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 624176,
        "TickSize": 0.01,
        "LotSize": 1,
        "CompanyName": "TATA STEEL LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 11532,
        "InstrumentType": 8,
        "Name": "ULTRACEMCO",
        "DisplayName": "ULTRACEMCO",
        "ISIN": "INE481G01011",
        "Description": "ULTRACEMCO-EQ",
        "Series": "EQ",
        "NameWithSeries": "ULTRACEMCO-EQ",
        "InstrumentID": 1100100011532,
        "PriceBand": {
            "High": 12573.05,
            "Low": 10287.05,
            "HighString": "12573.05",
            "LowString": "10287.05",
            "CreditRating": "10287.05-12573.05",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 8660,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "ULTRATECH CEMENT LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 2
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 4963,
        "InstrumentType": 8,
        "Name": "ICICIBANK",
        "DisplayName": "ICICIBANK",
        "ISIN": "INE090A01021",
        "Description": "ICICIBANK-EQ",
        "Series": "EQ",
        "NameWithSeries": "ICICIBANK-EQ",
        "InstrumentID": 1100100004963,
        "PriceBand": {
            "High": 1367.85,
            "Low": 1119.15,
            "HighString": "1367.85",
            "LowString": "1119.15",
            "CreditRating": "1119.15-1367.85",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 77502,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "ICICI BANK LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1232,
        "InstrumentType": 8,
        "Name": "GRASIM",
        "DisplayName": "GRASIM",
        "ISIN": "INE047A01021",
        "Description": "GRASIM-EQ",
        "Series": "EQ",
        "NameWithSeries": "GRASIM-EQ",
        "InstrumentID": 1100100001232,
        "PriceBand": {
            "High": 2994.65,
            "Low": 2450.2,
            "HighString": "2994.65",
            "LowString": "2450.20",
            "CreditRating": "2450.20-2994.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 36216,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "GRASIM INDUSTRIES LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 2
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 317,
        "InstrumentType": 8,
        "Name": "BAJFINANCE",
        "DisplayName": "BAJFINANCE",
        "ISIN": "INE296A01024",
        "Description": "BAJFINANCE-EQ",
        "Series": "EQ",
        "NameWithSeries": "BAJFINANCE-EQ",
        "InstrumentID": 1100100000317,
        "PriceBand": {
            "High": 8051.65,
            "Low": 6587.75,
            "HighString": "8051.65",
            "LowString": "6587.75",
            "CreditRating": "6587.75-8051.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 13617,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BAJAJ FINANCE LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 15083,
        "InstrumentType": 8,
        "Name": "ADANIPORTS",
        "DisplayName": "ADANIPORTS",
        "ISIN": "INE742F01042",
        "Description": "ADANIPORTS-EQ",
        "Series": "EQ",
        "NameWithSeries": "ADANIPORTS-EQ",
        "InstrumentID": 1100100015083,
        "PriceBand": {
            "High": 1558.9,
            "Low": 1275.5,
            "HighString": "1558.90",
            "LowString": "1275.50",
            "CreditRating": "1275.50-1558.90",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 69124,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "ADANI PORT & SEZ LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 16
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 16669,
        "InstrumentType": 8,
        "Name": "BAJAJ-AUTO",
        "DisplayName": "BAJAJ-AUTO",
        "ISIN": "INE917I01010",
        "Description": "BAJAJ-AUTO-EQ",
        "Series": "EQ",
        "NameWithSeries": "BAJAJ-AUTO-EQ",
        "InstrumentID": 1100100016669,
        "PriceBand": {
            "High": 13015.2,
            "Low": 10648.8,
            "HighString": "13015.20",
            "LowString": "10648.80",
            "CreditRating": "10648.80-13015.20",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 8377,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BAJAJ AUTO LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 1
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23262,
        "InstrumentType": 8,
        "Name": "BAJAJ-AUTO",
        "DisplayName": "BAJAJ-AUTO",
        "ISIN": "INE917I01010",
        "Description": "BAJAJ-AUTO-T0",
        "Series": "T0",
        "NameWithSeries": "BAJAJ-AUTO-T0",
        "InstrumentID": 1100100023262,
        "PriceBand": {
            "High": 11950.3,
            "Low": 11713.7,
            "HighString": "11950.30",
            "LowString": "11713.70",
            "CreditRating": "11713.70-11950.30",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 8377,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BAJAJ AUTO LIMITED-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 1
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 20374,
        "InstrumentType": 8,
        "Name": "COALINDIA",
        "DisplayName": "COALINDIA",
        "ISIN": "INE522F01014",
        "Description": "COALINDIA-EQ",
        "Series": "EQ",
        "NameWithSeries": "COALINDIA-EQ",
        "InstrumentID": 1100100020374,
        "PriceBand": {
            "High": 536.9,
            "Low": 439.3,
            "HighString": "536.90",
            "LowString": "439.30",
            "CreditRating": "439.30-536.90",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 203370,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "COAL INDIA LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 13
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1363,
        "InstrumentType": 8,
        "Name": "HINDALCO",
        "DisplayName": "HINDALCO",
        "ISIN": "INE038A01020",
        "Description": "HINDALCO-EQ",
        "Series": "EQ",
        "NameWithSeries": "HINDALCO-EQ",
        "InstrumentID": 1100100001363,
        "PriceBand": {
            "High": 803.15,
            "Low": 657.15,
            "HighString": "803.15",
            "LowString": "657.15",
            "CreditRating": "657.15-803.15",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 134832,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HINDALCO  INDUSTRIES  LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 13
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23263,
        "InstrumentType": 8,
        "Name": "HINDALCO",
        "DisplayName": "HINDALCO",
        "ISIN": "INE038A01020",
        "Description": "HINDALCO-T0",
        "Series": "T0",
        "NameWithSeries": "HINDALCO-T0",
        "InstrumentID": 1100100023263,
        "PriceBand": {
            "High": 737.45,
            "Low": 722.85,
            "HighString": "737.45",
            "LowString": "722.85",
            "CreditRating": "722.85-737.45",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 134832,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HINDALCO  INDUSTRIES  LTD-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 13
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 7229,
        "InstrumentType": 8,
        "Name": "HCLTECH",
        "DisplayName": "HCLTECH",
        "ISIN": "INE860A01027",
        "Description": "HCLTECH-EQ",
        "Series": "EQ",
        "NameWithSeries": "HCLTECH-EQ",
        "InstrumentID": 1100100007229,
        "PriceBand": {
            "High": 1989.5,
            "Low": 1627.8,
            "HighString": "1989.50",
            "LowString": "1627.80",
            "CreditRating": "1627.80-1989.50",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 54273,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HCL TECHNOLOGIES LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 11
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23258,
        "InstrumentType": 8,
        "Name": "ONGC",
        "DisplayName": "ONGC",
        "ISIN": "INE213A01029",
        "Description": "ONGC-T0",
        "Series": "T0",
        "NameWithSeries": "ONGC-T0",
        "InstrumentID": 1100100023258,
        "PriceBand": {
            "High": 291.6,
            "Low": 285.9,
            "HighString": "291.60",
            "LowString": "285.90",
            "CreditRating": "285.90-291.60",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 339667,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "OIL AND NATURAL GAS CORP.-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 2475,
        "InstrumentType": 8,
        "Name": "ONGC",
        "DisplayName": "ONGC",
        "ISIN": "INE213A01029",
        "Description": "ONGC-EQ",
        "Series": "EQ",
        "NameWithSeries": "ONGC-EQ",
        "InstrumentID": 1100100002475,
        "PriceBand": {
            "High": 317.6,
            "Low": 259.9,
            "HighString": "317.60",
            "LowString": "259.90",
            "CreditRating": "259.90-317.60",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 339667,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "OIL AND NATURAL GAS CORP.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23256,
        "InstrumentType": 8,
        "Name": "SBIN",
        "DisplayName": "SBIN",
        "ISIN": "INE062A01020",
        "Description": "SBIN-T0",
        "Series": "T0",
        "NameWithSeries": "SBIN-T0",
        "InstrumentID": 1100100023256,
        "PriceBand": {
            "High": 805.05,
            "Low": 789.15,
            "HighString": "805.05",
            "LowString": "789.15",
            "CreditRating": "789.15-805.05",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 124944,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "STATE BANK OF INDIA-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3045,
        "InstrumentType": 8,
        "Name": "SBIN",
        "DisplayName": "SBIN",
        "ISIN": "INE062A01020",
        "Description": "SBIN-EQ",
        "Series": "EQ",
        "NameWithSeries": "SBIN-EQ",
        "InstrumentID": 1100100003045,
        "PriceBand": {
            "High": 876.8,
            "Low": 717.4,
            "HighString": "876.80",
            "LowString": "717.40",
            "CreditRating": "717.40-876.80",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 124944,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "STATE BANK OF INDIA-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1660,
        "InstrumentType": 8,
        "Name": "ITC",
        "DisplayName": "ITC",
        "ISIN": "INE154A01025",
        "Description": "ITC-EQ",
        "Series": "EQ",
        "NameWithSeries": "ITC-EQ",
        "InstrumentID": 1100100001660,
        "PriceBand": {
            "High": 541.25,
            "Low": 442.85,
            "HighString": "541.25",
            "LowString": "442.85",
            "CreditRating": "442.85-541.25",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 200121,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "ITC LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 910,
        "InstrumentType": 8,
        "Name": "EICHERMOT",
        "DisplayName": "EICHERMOT",
        "ISIN": "INE066A01021",
        "Description": "EICHERMOT-EQ",
        "Series": "EQ",
        "NameWithSeries": "EICHERMOT-EQ",
        "InstrumentID": 1100100000910,
        "PriceBand": {
            "High": 5167.65,
            "Low": 4228.15,
            "HighString": "5167.65",
            "LowString": "4228.15",
            "CreditRating": "4228.15-5167.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 21102,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "EICHER MOTORS LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3432,
        "InstrumentType": 8,
        "Name": "TATACONSUM",
        "DisplayName": "TATACONSUM",
        "ISIN": "INE192A01025",
        "Description": "TATACONSUM-EQ",
        "Series": "EQ",
        "NameWithSeries": "TATACONSUM-EQ",
        "InstrumentID": 1100100003432,
        "PriceBand": {
            "High": 1225.55,
            "Low": 1002.75,
            "HighString": "1225.55",
            "LowString": "1002.75",
            "CreditRating": "1002.75-1225.55",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 89046,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TATA CONSUMER PRODUCT LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23271,
        "InstrumentType": 8,
        "Name": "NESTLEIND",
        "DisplayName": "NESTLEIND",
        "ISIN": "INE239A01024",
        "Description": "NESTLEIND-T0",
        "Series": "T0",
        "NameWithSeries": "NESTLEIND-T0",
        "InstrumentID": 1100100023271,
        "PriceBand": {
            "High": 2537.55,
            "Low": 2487.35,
            "HighString": "2537.55",
            "LowString": "2487.35",
            "CreditRating": "2487.35-2537.55",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 39530,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "NESTLE INDIA LIMITED-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 17963,
        "InstrumentType": 8,
        "Name": "NESTLEIND",
        "DisplayName": "NESTLEIND",
        "ISIN": "INE239A01024",
        "Description": "NESTLEIND-EQ",
        "Series": "EQ",
        "NameWithSeries": "NESTLEIND-EQ",
        "InstrumentID": 1100100017963,
        "PriceBand": {
            "High": 2763.65,
            "Low": 2261.2,
            "HighString": "2763.65",
            "LowString": "2261.20",
            "CreditRating": "2261.20-2763.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 39530,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "NESTLE INDIA LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 2885,
        "InstrumentType": 8,
        "Name": "RELIANCE",
        "DisplayName": "RELIANCE",
        "ISIN": "INE002A01018",
        "Description": "RELIANCE-EQ",
        "Series": "EQ",
        "NameWithSeries": "RELIANCE-EQ",
        "InstrumentID": 1100100002885,
        "PriceBand": {
            "High": 3016.3,
            "Low": 2467.9,
            "HighString": "3016.30",
            "LowString": "2467.90",
            "CreditRating": "2467.90-3016.30",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 33829,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "RELIANCE INDUSTRIES LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 11483,
        "InstrumentType": 8,
        "Name": "LT",
        "DisplayName": "LT",
        "ISIN": "INE018A01030",
        "Description": "LT-EQ",
        "Series": "EQ",
        "NameWithSeries": "LT-EQ",
        "InstrumentID": 1100100011483,
        "PriceBand": {
            "High": 3806.35,
            "Low": 3114.35,
            "HighString": "3806.35",
            "LowString": "3114.35",
            "CreditRating": "3114.35-3806.35",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 28874,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "LARSEN & TOUBRO LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 4
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 11536,
        "InstrumentType": 8,
        "Name": "TCS",
        "DisplayName": "TCS",
        "ISIN": "INE467B01029",
        "Description": "TCS-EQ",
        "Series": "EQ",
        "NameWithSeries": "TCS-EQ",
        "InstrumentID": 1100100011536,
        "PriceBand": {
            "High": 4650.1,
            "Low": 3804.7,
            "HighString": "4650.10",
            "LowString": "3804.70",
            "CreditRating": "3804.70-4650.10",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 21708,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TATA CONSULTANCY SERV LT-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 11
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 236,
        "InstrumentType": 8,
        "Name": "ASIANPAINT",
        "DisplayName": "ASIANPAINT",
        "ISIN": "INE021A01026",
        "Description": "ASIANPAINT-EQ",
        "Series": "EQ",
        "NameWithSeries": "ASIANPAINT-EQ",
        "InstrumentID": 1100100000236,
        "PriceBand": {
            "High": 3365,
            "Low": 2753.2,
            "HighString": "3365.00",
            "LowString": "2753.20",
            "CreditRating": "2753.20-3365.00",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 32612,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "ASIAN PAINTS LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1394,
        "InstrumentType": 8,
        "Name": "HINDUNILVR",
        "DisplayName": "HINDUNILVR",
        "ISIN": "INE030A01027",
        "Description": "HINDUNILVR-EQ",
        "Series": "EQ",
        "NameWithSeries": "HINDUNILVR-EQ",
        "InstrumentID": 1100100001394,
        "PriceBand": {
            "High": 3030.15,
            "Low": 2479.25,
            "HighString": "3030.15",
            "LowString": "2479.25",
            "CreditRating": "2479.25-3030.15",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 35243,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HINDUSTAN UNILEVER LTD.-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 10604,
        "InstrumentType": 8,
        "Name": "BHARTIARTL",
        "DisplayName": "BHARTIARTL",
        "ISIN": "INE397D01024",
        "Description": "BHARTIARTL-EQ",
        "Series": "EQ",
        "NameWithSeries": "BHARTIARTL-EQ",
        "InstrumentID": 1100100010604,
        "PriceBand": {
            "High": 1845.9,
            "Low": 1510.3,
            "HighString": "1845.90",
            "LowString": "1510.30",
            "CreditRating": "1510.30-1845.90",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 56931,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BHARTI AIRTEL LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 17
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23257,
        "InstrumentType": 8,
        "Name": "BPCL",
        "DisplayName": "BPCL",
        "ISIN": "INE029A01011",
        "Description": "BPCL-T0",
        "Series": "T0",
        "NameWithSeries": "BPCL-T0",
        "InstrumentID": 1100100023257,
        "PriceBand": {
            "High": 338.8,
            "Low": 332.1,
            "HighString": "338.80",
            "LowString": "332.10",
            "CreditRating": "332.10-338.80",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 295018,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BHARAT PETROLEUM CORP  LT-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 526,
        "InstrumentType": 8,
        "Name": "BPCL",
        "DisplayName": "BPCL",
        "ISIN": "INE029A01011",
        "Description": "BPCL-EQ",
        "Series": "EQ",
        "NameWithSeries": "BPCL-EQ",
        "InstrumentID": 1100100000526,
        "PriceBand": {
            "High": 368.95,
            "Low": 301.9,
            "HighString": "368.95",
            "LowString": "301.90",
            "CreditRating": "301.90-368.95",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 295018,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BHARAT PETROLEUM CORP  LT-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 6
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3787,
        "InstrumentType": 8,
        "Name": "WIPRO",
        "DisplayName": "WIPRO",
        "ISIN": "INE075A01022",
        "Description": "WIPRO-EQ",
        "Series": "EQ",
        "NameWithSeries": "WIPRO-EQ",
        "InstrumentID": 1100100003787,
        "PriceBand": {
            "High": 577.5,
            "Low": 472.5,
            "HighString": "577.50",
            "LowString": "472.50",
            "CreditRating": "472.50-577.50",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 188326,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "WIPRO LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 11
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1594,
        "InstrumentType": 8,
        "Name": "INFY",
        "DisplayName": "INFY",
        "ISIN": "INE009A01021",
        "Description": "INFY-EQ",
        "Series": "EQ",
        "NameWithSeries": "INFY-EQ",
        "InstrumentID": 1100100001594,
        "PriceBand": {
            "High": 2110.9,
            "Low": 1727.1,
            "HighString": "2110.90",
            "LowString": "1727.10",
            "CreditRating": "1727.10-2110.90",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 49825,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "INFOSYS LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 11
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3456,
        "InstrumentType": 8,
        "Name": "TATAMOTORS",
        "DisplayName": "TATAMOTORS",
        "ISIN": "INE155A01022",
        "Description": "TATAMOTORS-EQ",
        "Series": "EQ",
        "NameWithSeries": "TATAMOTORS-EQ",
        "InstrumentID": 1100100003456,
        "PriceBand": {
            "High": 1021.35,
            "Low": 835.65,
            "HighString": "1021.35",
            "LowString": "835.65",
            "CreditRating": "835.65-1021.35",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 106745,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TATA MOTORS LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 1
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 4306,
        "InstrumentType": 8,
        "Name": "SHRIRAMFIN",
        "DisplayName": "SHRIRAMFIN",
        "ISIN": "INE721A01013",
        "Description": "SHRIRAMFIN-EQ",
        "Series": "EQ",
        "NameWithSeries": "SHRIRAMFIN-EQ",
        "InstrumentID": 1100100004306,
        "PriceBand": {
            "High": 3671.4,
            "Low": 3003.9,
            "HighString": "3671.40",
            "LowString": "3003.90",
            "CreditRating": "3003.90-3671.40",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 29703,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "SHRIRAM FINANCE LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 8
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 881,
        "InstrumentType": 8,
        "Name": "DRREDDY",
        "DisplayName": "DRREDDY",
        "ISIN": "INE089A01023",
        "Description": "DRREDDY-EQ",
        "Series": "EQ",
        "NameWithSeries": "DRREDDY-EQ",
        "InstrumentID": 1100100000881,
        "PriceBand": {
            "High": 7241.4,
            "Low": 5924.8,
            "HighString": "7241.40",
            "LowString": "5924.80",
            "CreditRating": "5924.80-7241.40",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 15185,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "DR. REDDY S LABORATORIES-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 15
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3506,
        "InstrumentType": 8,
        "Name": "TITAN",
        "DisplayName": "TITAN",
        "ISIN": "INE280A01028",
        "Description": "TITAN-EQ",
        "Series": "EQ",
        "NameWithSeries": "TITAN-EQ",
        "InstrumentID": 1100100003506,
        "PriceBand": {
            "High": 3791.95,
            "Low": 3102.55,
            "HighString": "3791.95",
            "LowString": "3102.55",
            "CreditRating": "3102.55-3791.95",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 28409,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TITAN COMPANY LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 157,
        "InstrumentType": 8,
        "Name": "APOLLOHOSP",
        "DisplayName": "APOLLOHOSP",
        "ISIN": "INE437A01024",
        "Description": "APOLLOHOSP-EQ",
        "Series": "EQ",
        "NameWithSeries": "APOLLOHOSP-EQ",
        "InstrumentID": 1100100000157,
        "PriceBand": {
            "High": 7650.55,
            "Low": 6259.55,
            "HighString": "7650.55",
            "LowString": "6259.55",
            "CreditRating": "6259.55-7650.55",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 14234,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "APOLLO HOSPITALS ENTER. L-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 9
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 547,
        "InstrumentType": 8,
        "Name": "BRITANNIA",
        "DisplayName": "BRITANNIA",
        "ISIN": "INE216A01030",
        "Description": "BRITANNIA-EQ",
        "Series": "EQ",
        "NameWithSeries": "BRITANNIA-EQ",
        "InstrumentID": 1100100000547,
        "PriceBand": {
            "High": 6602.35,
            "Low": 5401.95,
            "HighString": "6602.35",
            "LowString": "5401.95",
            "CreditRating": "5401.95-6602.35",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 16619,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "BRITANNIA INDUSTRIES LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 0
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 13538,
        "InstrumentType": 8,
        "Name": "TECHM",
        "DisplayName": "TECHM",
        "ISIN": "INE669C01036",
        "Description": "TECHM-EQ",
        "Series": "EQ",
        "NameWithSeries": "TECHM-EQ",
        "InstrumentID": 1100100013538,
        "PriceBand": {
            "High": 1781.65,
            "Low": 1457.75,
            "HighString": "1781.65",
            "LowString": "1457.75",
            "CreditRating": "1457.75-1781.65",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 61627,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TECH MAHINDRA LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 11
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1348,
        "InstrumentType": 8,
        "Name": "HEROMOTOCO",
        "DisplayName": "HEROMOTOCO",
        "ISIN": "INE158A01026",
        "Description": "HEROMOTOCO-EQ",
        "Series": "EQ",
        "NameWithSeries": "HEROMOTOCO-EQ",
        "InstrumentID": 1100100001348,
        "PriceBand": {
            "High": 6001.7,
            "Low": 4910.5,
            "HighString": "6001.70",
            "LowString": "4910.50",
            "CreditRating": "4910.50-6001.70",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 18198,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "HERO MOTOCORP LIMITED-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 1
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 23265,
        "InstrumentType": 8,
        "Name": "TRENT",
        "DisplayName": "TRENT",
        "ISIN": "INE849A01020",
        "Description": "TRENT-T0",
        "Series": "T0",
        "NameWithSeries": "TRENT-T0",
        "InstrumentID": 1100100023265,
        "PriceBand": {
            "High": 8109.1,
            "Low": 7948.6,
            "HighString": "8109.10",
            "LowString": "7948.60",
            "CreditRating": "7948.60-8109.10",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 12442,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TRENT LTD-T0",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 1964,
        "InstrumentType": 8,
        "Name": "TRENT",
        "DisplayName": "TRENT",
        "ISIN": "INE849A01020",
        "Description": "TRENT-EQ",
        "Series": "EQ",
        "NameWithSeries": "TRENT-EQ",
        "InstrumentID": 1100100001964,
        "PriceBand": {
            "High": 8831.7,
            "Low": 7226,
            "HighString": "8831.70",
            "LowString": "7226.00",
            "CreditRating": "7226.00-8831.70",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 12442,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "TRENT LTD-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 5
    },
    {
        "ExchangeSegment": 1,
        "ExchangeInstrumentID": 3351,
        "InstrumentType": 8,
        "Name": "SUNPHARMA",
        "DisplayName": "SUNPHARMA",
        "ISIN": "INE044A01036",
        "Description": "SUNPHARMA-EQ",
        "Series": "EQ",
        "NameWithSeries": "SUNPHARMA-EQ",
        "InstrumentID": 1100100003351,
        "PriceBand": {
            "High": 2076.5,
            "Low": 1699,
            "HighString": "2076.50",
            "LowString": "1699.00",
            "CreditRating": "1699.00-2076.50",
            "HighExecBandString": "0.00",
            "LowExecBandString": "0.00",
            "HighExecBand": 0,
            "LowExecBand": 0,
            "TERRange": "0.00-0.00"
        },
        "FreezeQty": 52785,
        "TickSize": 0.05,
        "LotSize": 1,
        "CompanyName": "SUN PHARMACEUTICAL IND L-EQ",
        "DecimalDisplace": 2,
        "IsIndex": false,
        "IsTradeable": true,
        "Industry": 15
    }
];


export const testJson = {
    "157": "APOLLOHOSP",
    "236": "ASIANPAINT",
    "317": "BAJFINANCE",
    "383": "BEL",
    "467": "HDFCLIFE",
    "526": "BPCL",
    "547": "BRITANNIA",
    "881": "DRREDDY",
    "910": "EICHERMOT",
    "1232": "GRASIM",
    "1333": "HDFCBANK",
    "1348": "HEROMOTOCO",
    "1363": "HINDALCO",
    "1394": "HINDUNILVR",
    "1594": "INFY",
    "1660": "ITC",
    "1922": "KOTAKBANK",
    "1964": "TRENT",
    "2475": "ONGC",
    "2885": "RELIANCE",
    "3045": "SBIN",
    "3351": "SUNPHARMA",
    "3432": "TATACONSUM",
    "3456": "TATAMOTORS",
    "3499": "TATASTEEL",
    "3506": "TITAN",
    "3787": "WIPRO",
    "4306": "SHRIRAMFIN",
    "4963": "ICICIBANK",
    "5258": "INDUSINDBK",
    "5900": "AXISBANK",
    "7229": "HCLTECH",
    "10604": "BHARTIARTL",
    "10999": "MARUTI",
    "11483": "LT",
    "11532": "ULTRACEMCO",
    "11536": "TCS",
    "11630": "NTPC",
    "11723": "JSWSTEEL",
    "13538": "TECHM",
    "14977": "POWERGRID",
    "15083": "ADANIPORTS",
    "16669": "BAJAJ-AUTO",
    "16675": "BAJAJFINSV",
    "17963": "NESTLEIND",
    "20374": "COALINDIA",
    "21808": "SBILIFE",
    "23256": "SBIN",
    "23257": "BPCL",
    "23258": "ONGC",
    "23262": "BAJAJ-AUTO",
    "23263": "HINDALCO",
    "23265": "TRENT",
    "23271": "NESTLEIND",
    "23278": "JSWSTEEL"
}