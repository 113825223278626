import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { instruments, testJson } from './instruments';
import './App.css';
import calculateSellOrBy from './utilities/calculateSellOrBuy';


const RealTimeStockData = () => {
    const [stockData, setStockData] = useState([]);
    const [instrument, setInstrument] = useState([]);
    const [accessToken, setAccessToken] = useState(null);

    const isWithinTradingHours = () => {
        const now = new Date();
        const currentISTTime = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));

        const start = new Date(currentISTTime);
        start.setHours(8, 45, 0, 0); // 8:45 AM IST

        const end = new Date(currentISTTime);
        end.setHours(15, 15, 0, 0); // 3:15 PM IST

        return currentISTTime >= start && currentISTTime <= end;
    };

    const xtsData = {
        "secretKey": "Iygd065@3I",
        "appKey": "200e6557472359a1aa8249",
        "source": "WEBAPI",
        "userId": "DIRC18N107"
    }

    async function login(params) {
        try {
            const logIn = await axios.post('https://algozy.rathi.com:3000/apimarketdata/auth/login', {
                "secretKey": "Iygd065@3I",
                "appKey": "200e6557472359a1aa8249",
                "source": "WEBAPI",
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const dataToSet = await axios.post("https://algozy.rathi.com:3000/apimarketdata/instruments/quotes", {
                "instruments": instruments.slice(0, 50).map(ele => ({
                    exchangeInstrumentID: ele.ExchangeInstrumentID,
                    exchangeSegment: ele.ExchangeSegment
                })),
                "xtsMessageCode": 1502,
                "publishFormat": "JSON"
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "authorization": logIn?.data?.result?.token
                    },
                }
            );

            const dataToSet2 = await axios.post("https://algozy.rathi.com:3000/apimarketdata/instruments/quotes", {
                "instruments": instruments.slice(50, 55).map(ele => ({
                    exchangeInstrumentID: ele.ExchangeInstrumentID,
                    exchangeSegment: ele.ExchangeSegment
                })),
                "xtsMessageCode": 1502,
                "publishFormat": "JSON"
            },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "authorization": logIn?.data?.result?.token
                    },
                }
            );


            setStockData(pre => ([...dataToSet.data.result.listQuotes, ...dataToSet2.data.result.listQuotes]))
            setAccessToken(logIn?.data?.result?.token)
            await login()

        } catch (error) {
            setAccessToken(null)
            return null
        }

    }

    useEffect(() => {
        login()
    }, [])

    return (
        <div className="right_section">
            <>
                <div className="search_header">
                    <div className="title">Real Time stock Data</div>

                </div>
                <div className="data_setion">
                    {/* <div className="table_main user_management_table">
                        <div className="table_header">
                            <div className="srno">
                                <h5>Sr. No.</h5>
                            </div>
                            <div className="user_name">
                                <h5>Symbol</h5>
                            </div>
                            <div className="creationDate">
                                <h5>Open</h5>
                            </div>
                            <div className="user_id">
                                <h5>Close</h5>
                            </div>
                            <div className="creationDate">
                                <h5>High</h5>
                            </div>
                            <div className="creationDate">
                                <h5>Low</h5>
                            </div>
                            <div className="creationDate">
                                <h5>LTP</h5>
                            </div>
                            <div className="creationDate">
                                <h5>%Change</h5>
                            </div>
                            <div className="action">
                                <h5>Action</h5>
                            </div>
                        </div>
                        {stockData.length > 0 ? (
                            <>
                                {stockData.map((data, index) => {
                                    const parseData = JSON.parse(data);
                                    const { Open, Close, High, Low, LastTradedPrice, PercentChange } = parseData?.Touchline;
                                    const { sell, buy } = isWithinTradingHours() ? calculateSellOrBy(Open, Close, High, Low) : { sell: false, buy: false }
                                    return (
                                        <div className="table_data_gap">
                                            <div className="table_data">
                                                <div className="srno">
                                                    <h6>{(index + 1)}</h6>
                                                </div>
                                                <div className="user_name">
                                                    <h6>{testJson[`${parseData?.ExchangeInstrumentID}`]}</h6>
                                                </div>
                                                <div className="creationDate">
                                                    <h6>
                                                        {Open}
                                                    </h6>
                                                </div>
                                                <div className="user_id">
                                                    <h6>{Close}</h6>
                                                </div>
                                                <div className="creationDate">
                                                    <h6>{High}</h6>
                                                </div>
                                                <div className="creationDate">
                                                    <h6>{Low}</h6>
                                                </div>
                                                <div className="creationDate">
                                                    <h6>{LastTradedPrice}</h6>
                                                </div>
                                                <div className="creationDate">
                                                    <h6>{PercentChange}</h6>
                                                </div>
                                                <div className="action">
                                                    {sell && <button style={{ marginRight: '10px' }} className='btn_search_section'>Sell</button>}
                                                    {buy && <button className='btn_search_section'>Buy</button>}
                                                    {(sell === false && buy === false) && <h6>--</h6>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>

                        ) : (
                            <h4 className="data_not_available">Data not available</h4>
                        )}
                    </div> */}
                    <div className='table-responsive'>


                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr no</th>
                                    <th>Symbol</th>
                                    <th>Open</th>
                                    <th>Close</th>
                                    <th>High</th>
                                    <th>Low</th>
                                    <th>LTP</th>
                                    <th>%Change</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockData.length > 0 ? (
                                    <>
                                        {stockData.map((data, index) => {
                                            const parseData = JSON.parse(data);
                                            const { Open, Close, High, Low, LastTradedPrice, PercentChange } = parseData?.Touchline;
                                            const { sell, buy } = isWithinTradingHours() ? calculateSellOrBy(Open, Close, High, Low) : { sell: false, buy: false }
                                            return (

                                                <tr>
                                                    <td>
                                                        {(index + 1)}
                                                    </td>
                                                    <td>
                                                        {testJson[`${parseData?.ExchangeInstrumentID}`]}
                                                    </td>
                                                    <td>
                                                        {Open}
                                                    </td>
                                                    <td >
                                                        {Close}
                                                    </td>
                                                    <td >
                                                        {High}
                                                    </td>
                                                    <td >
                                                        {Low}
                                                    </td>
                                                    <td >
                                                        {LastTradedPrice}
                                                    </td>
                                                    <td >
                                                        {PercentChange?.toFixed(2)}
                                                    </td>
                                                    <td >
                                                        {sell && <button style={{ marginRight: '10px', background:"red" }} className='btn_search_section'>Sell</button>}
                                                        {buy && <button style={{  background:"green" }} className='btn_search_section'>Buy</button>}
                                                        {(sell === false && buy === false) && <h6>--</h6>}
                                                    </td>
                                                </tr>

                                            );
                                        })}
                                    </>

                                ) : (
                                    <h4 className="data_not_available">Data not available</h4>
                                )}

                            </tbody>
                        </table>
                    </div>
                </div>
            </>

        </div>



    );
};

export default RealTimeStockData;
