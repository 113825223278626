import React, { useEffect ,useState ,useCallback } from 'react'
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";

const DashboardHeader = () => {
    const navigate = useNavigate();

    const logOut = async ()=>{ 
        swal({
            title: "Are you sure?",
            text: `You want to logout `,
            icon: "warning",
            buttons: ["No, cancel it!", "Yes, I am sure!"],
            dangerMode: true,
          }).then(async function (isConfirm) {
            if (isConfirm) {
              navigate("/login")
            
            } else {
              return;
            }
          });
       
    }
    return (
        <>
            <header className='header_main'>
                <div className="header_text row">
                    <div className="col-11 user_name_text">
                        <h4>{`Welcome,`}</h4>
                    </div>
                    <div className="col-1 logout_icon">
                        <i className="fa-solid fa-power-off" onClick={logOut} title="Logout"></i>
                    </div>
                </div>
            </header>
        </>
    )
}

export default DashboardHeader