function calculateSellOrBy(open = 0, close = 0, high = 0, low = 0,) {
    try {
        const DPP = ((low + high + close) / 3);
        const s1 = (2 * DPP) - high;
        const s2 = (DPP - (high - low));
        const s3 = (DPP - (2 * (high - DPP)))

        const r1 = (2 * DPP) - low;
        const r2 = (DPP + (high - low));
        const r3 = high + (2 * (DPP - low));
        let sell = false, buy = false;

        // sell conditions
        if (((open - r1) / r1) <= 0.005) {
            console.log("inside the sell condition for nearby r1");
            sell = true
            // return "sell";
        }

        if (((open - r3) / r3) <= 0.005) {
            console.log("inside the sell condition for nearby r3");
            sell = true
            // return "sell";
        }

        if (open === high) {
            console.log("inside the sell condition for open === high");
            sell = true
            // return "sell"
        }

        // buy condition
        if (((open > (DPP * 1.004)) && (open > (close * 1.004)))) {
            console.log("inside the buy condition for (open > dpp*1.004) &&( open > close *1.004)");
            buy = true
            // return "buy";
        }

        if (((open > (DPP * 1.004)) && (open > (high * 1.004)))) {
            console.log("inside the buy condition for (open > dpp*1.004) &&( open > high *1.004)");
            buy = true
            // return "buy";
        }

        if (((open < (DPP * 0.996)) && (open < (low * 0.996)))) {
            console.log("inside the buy condition for (open < dpp*0.996) &&( open < low *0.996)");
            buy = true
            // return "buy";
        }

        if (((open - s1) / s1) <= 0.005) {
            console.log("inside the buy condition for nearby s1");
            buy = true
            // return "buy";
        }

        if (((open - s3) / s3) <= 0.005) {
            console.log("inside the buy condition for nearby s3");
            buy = true
            // return "buy";
        }

        if (open === low) {
            console.log("inside the buy condition for open === low");
            buy = true
            // return "buy";
        }

        console.log("close = " + close);
        console.log("high = " + high);
        console.log("open = " + open);
        console.log("low = " + low);
        console.log("s1 = " + s1);
        console.log("s2 = " + s2);
        console.log("s3 = " + s3);
        console.log("r1 = " + r1);
        console.log("r2 = " + r2);
        console.log("r3 = " + r3);
        console.log("close = " + close);
        console.log("high = " + high);

        return {
            sell, buy
        }

    } catch (error) {
        console.log("error inside the calculation", error.message);

        return { sell: false, buy: false };
    }
}
export default calculateSellOrBy;