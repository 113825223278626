import logo from './logo.svg';
import './App.css';
// import "./style/index.css"
// import "./style/media.css"
import DashboardRoute from './routes/DashboardRoute';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  return (
    <>
      <Router>
        <DashboardRoute />
      </Router>
    </>
  );
}

export default App;
